/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://use.typekit.net/sar6rny.css");

body {
  /* font-family: 'Inter', monospace; */
  font-family: "kumbh-sans-variable", sans-serif;
  font-variation-settings: "wght" 294;
}

:root {
  --red: #ae0000;
}


.btn {
  background-color: var(--red);
  transition: 1s;
  outline: none;
  border: var(--red);
}

.btn:hover {
  background-color: gray;
}